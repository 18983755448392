import { defineStore } from "pinia";
import { useLocalStorage, useMouse } from "@vueuse/core";
import axios from "axios";
import APIRequest from "@/libs/cloud_auth";
import * as htmlToImage from "html-to-image";
import { nextTick } from "vue";
import { useAppStore } from "../app";

const NEW_DEFAULT = {};

const THEMES = [
  {
    type: "wayfinder",
    theme: [
      {
        primary: "#ff6d6d",
        secondary: "#24b472",
        txt_primary: "#FFFFFF",
        txt_secondary: "#FFFFFF",
      },
      {
        primary: "#685793",
        secondary: "#f1aa00",
        txt_primary: "#FFFFFF",
        txt_secondary: "#FFFFFF",
      },
      {
        primary: "#253444",
        secondary: "#00af8b",
        txt_primary: "#FFFFFF",
        txt_secondary: "#FFFFFF",
      },
      {
        primary: "#2543cf",
        secondary: "#4360e7",
        txt_primary: "#FFFFFF",
        txt_secondary: "#FFFFFF",
      },
      {
        primary: "#4b372f",
        secondary: "#b49286",
        txt_primary: "#FFFFFF",
        txt_secondary: "#FFFFFF",
      },
    ],
  },
  {
    type: "flights",
    theme: [
      {
        primary: "#2e2e2e",
        secondary: "#5f5f5f",
        tertiary: "#3c3c3c",
        txt_primary: "#ffffff",
        txt_secondary: "#FFFFFF",
      },
      {
        primary: "#daeef9",
        secondary: "#0069bd",
        tertiary: "#0091db",
        txt_primary: "#0069bd",
        txt_secondary: "#FFFFFF",
      },
      {
        primary: "#253444",
        secondary: "#00af8b",
        tertiary: "#253444",
        txt_primary: "#FFFFFF",
        txt_secondary: "#FFFFFF",
      },
      {
        primary: "#cfe7f2",
        secondary: "#3f3244",
        tertiary: "#60495A",
        txt_primary: "#3f3244",
        txt_secondary: "#FFFFFF",
      },
      {
        primary: "#c7ff5c",
        secondary: "#212121",
        tertiary: "#2c364a",
        txt_primary: "#212121",
        txt_secondary: "#FFFFFF",
      },
    ],
  },
  {
    type: "islamic",
    theme: [
      {
        primary: "#212121",
        secondary: "#222732",
        txt_primary: "#EEB9B9",
        txt_secondary: "#FFFFFF",
      },
      {
        primary: "#FAEDCD",
        secondary: "#FEFAE0",
        txt_primary: "#D4A373",
        txt_secondary: "#815F0E",
      },
      {
        primary: "#344E41",
        secondary: "#3A5A40",
        txt_primary: "#A3B18A",
        txt_secondary: "#DAD7CD",
      },
      {
        primary: "#D3EDEE",
        secondary: "#F1FAEE",
        txt_primary: "#457B9D",
        txt_secondary: "#1D3557",
      },
      {
        primary: "#F27059",
        secondary: "#F25C54",
        txt_primary: "#F9C48B",
        txt_secondary: "#FEF5EC",
      },
    ]
  },
  {
    type: "rates",
    theme: [
      {
        highlight: '#C7FF5C',
        primary: "#212121",
        secondary: "#222732",
        txt_primary: "#212121",
        txt_secondary: "#FFFFFF",
      },
      {
        highlight: '#382906',
        primary: "#FAEDCD",
        secondary: "#FEFAE0",
        txt_primary: "#FEFAE0",
        txt_secondary: "#815F0E",
      },
      {
        highlight: '#A28A2A',
        primary: "#344E41",
        secondary: "#3A5A40",
        txt_primary: "#FFFFFF",
        txt_secondary: "#DAD7CD",
      },
      {
        highlight: '#275F91',
        primary: "#CEE1F2",
        secondary: "#F1FAEE",
        txt_primary: "#F1FAEE",
        txt_secondary: "#457B9D",
      },
      {
        highlight: '#5ED4C4',
        primary: "#264653",
        secondary: "#2A9D8F",
        txt_primary: "#13242A",
        txt_secondary: "#FEF5EC",
      },
    ]
  }
];

const { x: mouseX, y: mouseY } = useMouse();
const clamp = (value, min, max) => {
  return value < min ? min : value > max ? max : value;
};

const filter = (node) => {
  const exclusionClasses = ['handle', 'container-widgetSettings', 'video-tag'];
  return !exclusionClasses.some((classname) => node.classList?.contains(classname));
}

export const composerStore = defineStore("composer", {
  state: () => ({
    mounted: false,
    elementSelected: false,
    locked: false,
    interacting: false,
    galleryBucket: 0,
    galleryUpload: false,
    galleryGroup: false,
    presentationDetails: {},
    presentation: NEW_DEFAULT,
    showOverlay: false,
    galleryType: "",
    showMenuSettings: false,
    indexElement: -1,
    indexDataArray: 0,
    configListView: false,
    temp: " ",
    settings: "default",
    preventDelete: false,
    x: 0,
    y: 0,
    color: "#fff",
    zoom: 1,
    zoomStep: 0.1,
    coordinatesX: 0,
    coordinatesY: 0,
    configX: 0,
    configY: 0,
    shape: "square",
    showGrid: useLocalStorage("grid", true),
    showGallery: false,
    showTvChannels: false,
    idInputText: 0,
    videoDuration: 10,
    selectedEditor: null,
    selectedTable: null,
    onEditor: false,
    dropzone: null,
    configSlides: false,
    indexArrayTemp: -1,
    check: false,
    saveDraft: false,
    dragFromGallery: false,
    test: false,
    column_index: 0,
    row_index: 0,
    indexRow: 0,
    indexCell: 0,
    intervalIndex: [],
    intervalIndexReal: [],
    tableEditor: false,
    selectedFolder: "",
    mergeWarning: false,
    merge: false,
    history: [],
    historyIndex: -1,
    historyLimit: 10, // Specify the history limit
    customThemes: THEMES,
    elementCopy: null,
    widgetLoading: false,
    selectedItemNum: 0,
    hasVideoPlaylist: false,
  }),

  actions: {
    bringToFront() {
      if (this.selectedItemNum > 1) {
        const selectedItems = this.getSelectedItems;
        selectedItems.forEach(item => {
            const index = this.slideElements.indexOf(item);
            if (index !== -1) {
                this.slideElements.splice(index, 1);
            }
            this.slideElements.push(item);
        });

        this.configListView = false;
        this.addToHistory();
        return;
      }

      if (!this.selectedWidget || this.isWidgetLocked) return;
      if (this.indexDataArray != this.slideElements.length - 1) {
        let element = this.selectedWidget;
        this.deleteElement(this.indexElement);
        this.addElement(element);
      }
      this.configListView = false;
      this.addToHistory();
    },
    sendToBack() {
      if (this.selectedItemNum > 1) {
          const selectedItems = this.getSelectedItems; 
          selectedItems.forEach(item => {
              const index = this.slideElements.indexOf(item);
              if (index !== -1) {
                  this.slideElements.splice(index, 1);
              }
              this.slideElements.unshift(item);
          });

          this.indexElement = 0;
          this.showMenuSettings = true;
          this.configListView = false;
          return;
      }

      if (!this.selectedWidget || this.isWidgetLocked) return;
      let element = this.selectedWidget;
      this.deleteElement(this.indexElement);
      this.slideElements.unshift(element);
      this.indexElement = 0;
      this.showMenuSettings = true;
      this.configListView = false;
      this.addToHistory();
    },
    sendBackward() {
      if (!this.selectedWidget || this.isWidgetLocked) return;
      if (this.indexElement != 0) {
        this.presentation.slides[this.indexDataArray].elements.splice(
          this.indexElement - 1,
          0,
          this.presentation.slides[this.indexDataArray].elements.splice(
            this.indexElement,
            1
          )[0]
        );
        this.indexElement -= 1;
      }
      this.configListView = false;
      this.addToHistory();
    },
    sendForward() {
      if (!this.selectedWidget || this.isWidgetLocked) return;
      if (this.indexElement < this.slideElements.length - 1) {
        this.presentation.slides[this.indexDataArray].elements.splice(
          this.indexElement + 1,
          0,
          this.presentation.slides[this.indexDataArray].elements.splice(
            this.indexElement,
            1
          )[0]
        );
        this.indexElement += 1;
      }
      this.configListView = false;
      this.addToHistory();
    },
    copyElement() {
      if(this.selectedItemNum > 1) {
        let found = false;

        this.getSelectedItems.forEach((element) => {
          console.log(element.name);
          if (["VideoWidget", "TVWidget", "MediaPlaylistWidget"].includes(element.name)) found = true;
        });
        if(found) return;

        this.elementCopy = JSON.parse(JSON.stringify(this.getSelectedItems));
        return;
      }

      if (
        !this.selectedWidget ||
        this.isWidgetLocked ||
        this.currentWidgetNBrowser ||
        (this.selectedWidget.name && this.selectedWidget.name === 'MediaPlaylistWidget')
      )
        return;

      this.elementCopy = JSON.parse(JSON.stringify(this.selectedWidget));
    },
    pasteElement() {
      if (!this.elementCopy || this.onEditor || this.selectedTable || this.isSlideLocked) return;
      this.handlePaste();
      this.handleScale();
      this.addToHistory();
    },
    handlePaste(copyItems = this.elementCopy) {
      const grid = document.querySelector("#grid");
      if (!grid) return;

      const stateCopy = JSON.parse(JSON.stringify(copyItems));
      this.disableElements();
      this.unSelectElements();

      const rect = grid.getBoundingClientRect();
      const offset = 25;

      if(stateCopy.length > 1) {
        const new_group_id = Date.now();
        nextTick(() => {
          this.selectedItemNum = 0;
          stateCopy.forEach((el) => {
            el.id = Date.now();
            el.top += offset;
            el.left += offset;
            el.isSelected = true;
            if(el.group_id) {
              el.group_id = new_group_id;
            }
            this.selectedItemNum += 1;
            this.addElement(el);
          })
        });
      } else {
          
        if (this.settings !== stateCopy.name) {
          this.showGallery = false;
        }
        this.settings = stateCopy.name;
        // Calculate mouse position relative to the grid
        const xPos = (mouseX.value - rect.left) / this.zoom - stateCopy.width / 2;
        const yPos = (mouseY.value - rect.top) / this.zoom - stateCopy.height / 2;

        stateCopy.id = Date.now();
        stateCopy.top = clamp(yPos, 0, grid.offsetHeight - stateCopy.height);
        stateCopy.left = clamp(xPos, 0, grid.offsetWidth - stateCopy.width);
        this.addElement(stateCopy);
      }
    },
    unGroupWidgets() {
      this.getSelectedItems.forEach((el) => {
        delete el.group_id
      })
      this.addToHistory();
    },
    groupWidgets() {
      const group_id = Date.now();
      this.getSelectedItems.forEach((el) => {
        el.group_id = group_id
      })
      this.addToHistory();
    },
    handleScale() {
      nextTick(() => {
        let handles = document.querySelectorAll(".my-active-class .handle");
        handles.forEach((el) => {
          // If scale above 100% zoom => 1 / value < 1 ? 1 : 1 / value;
          el.style.scale = 1 / this.zoom;
        });
      });
    },
    toggleGallery(type = 'image') {
      this.showGallery = !this.showGallery;
      if(!this.showGallery) this.galleryType = '';
      else this.galleryType = type;
    },
    // Will be useful in the future
    async takeThumbnailPic(canvas) {
      const index = this.indexDataArray;
      const el = canvas;

      try {
        const dataUrl = await htmlToImage.toJpeg(el, {
          backgroundColor: "transparent",
          width: el.clientWidth,
          height: el.clientHeight,
          canvasWidth: el.clientWidth / 2,
          canvasHeight: el.clientHeight / 2,
          imagePlaceholder:
            "data:image/gif;base64,R0lGODlhAQABAIAAAMLCwgAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw==",
          pixelRatio: 1,
          quality: 0.5,
          cacheBust: true,
          filter: filter, // Assuming filter is defined elsewhere
          style: {
            transformOrigin: "top left",
            transform: "scale(1)",
          },
          skipFonts: true,
          preferredFontFormat: 'woff2',
          skipAutoScale: true,
        });

        if (dataUrl) {
          this.presentation.slides[index].settings.thumbnail = dataUrl;
        }
      } catch (error) {
        console.error("Error capturing image:", error);
      } finally {
        this.picInProgress = false;
      }
    },
    getTheme(name) {
      return this.customThemes.find((theme) => theme.type === name).theme;
    },
    resetHistory() {
      this.history = [];
      this.historyIndex = -1;
    },
    addToHistory() {
      nextTick().then(() => {
        const stateCopy = JSON.parse(
          JSON.stringify(
            this.presentation.slides.map((slide) => ({
              ...slide,
              elements: slide.elements.map((element) => ({
                ...element,
                isSelected: false,
                active: false,
              })),
            }))
          )
        );

        this.history = this.history.slice(0, this.historyIndex + 1);
        this.history.push(stateCopy);
        this.historyIndex++;

        if (this.history.length > this.historyLimit) {
          this.history.shift();
          this.historyIndex--;
        }
      });
    },
    undo() {
      if (this.canUndo) {
        if (document.querySelector(".vue-drag-resize-rotate.dragging"))
          return false;

        this.undoRedoCommonTasks();

        const previousState = JSON.parse(
          JSON.stringify(this.history[--this.historyIndex])
        );
        this.presentation.slides = previousState;
      }
    },
    redo() {
      if (this.canRedo) {
        if (document.querySelector(".vue-drag-resize-rotate.dragging"))
          return false;

        this.undoRedoCommonTasks();

        const nextState = JSON.parse(
          JSON.stringify(this.history[++this.historyIndex])
        );
        this.presentation.slides = nextState;
      }
    },
    undoRedoCommonTasks() {
      if (this.selectedEditor) {
        this.selectedEditor.commands.blur();
        this.selectedEditor.setEditable(false);
      }
      if (this.selectedTable) {
        this.selectedTable.commands.blur();
      }
      this.onEditor = false;
      this.settings = "default";
      this.configSlides = false;
      this.showMenuSettings = false;
      this.indexElement = -1;
      this.unSelectElements();
    },
    async getIslamicCalendar(date, lat = 41, lon = -8, tenant = useAppStore().getCurrentTenant) {
      return new Promise((resolve, reject) => {
        axios.post(`islamic_calendar/?latitude=${lat}&longitude=${lon}&date=${date}&tenant=${tenant}`)
          .then((response) => {
            if(response?.status === 200){
            const data = response.data;
            resolve(data);
            }
            else{
              reject([]);
            }
          })
          .catch(() => {
            reject([]);
          });
      });
    },
    async getExchangeRates(source, currency) {
      return new Promise((resolve, reject) => {
        axios.post("get_exchange_rates/",{
          source,
            currency
        })
          .then((response) => {
            const data = response.data;
            resolve(data);
          })
          .catch(() => {
            reject([]);
          });
      });
    },
    async getTvChannels() {
      return new Promise((resolve, reject) => {
        APIRequest(
          "widgets/tvwidget/?page_size=999999&tenant=" +
            useAppStore().getCurrentTenant
        )
          .then((response) => {
            const data = response.data["results"];
            const urlList = [];
            data.forEach((element) => {
              urlList.push({
                id: element["id"],
                name: element["name"],
                value: element["url"],
              });
            });
            resolve(urlList);
          })
          .catch(() => {
            reject([]);
          });
      });
    },
    async saveTvChannel(name, url) {
      return new Promise((resolve, reject) => {
        APIRequest("widgets/tvwidget/", "post", {
          name: name,
          url: url,
          tenant: useAppStore().getCurrentTenant,
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async updateTvChannel(id, name, url) {
      return new Promise((resolve, reject) => {
        APIRequest(`widgets/tvwidget/${id}/`, "patch", {
          name: name,
          url: url,
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async deleteTvChannel(id) {
      return new Promise((resolve, reject) => {
        APIRequest(`widgets/tvwidget/${id}/`, "delete")
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async getIFrameUrls() {
      return new Promise((resolve, reject) => {
        APIRequest(
          "widgets/iframe/?page_size=999999&tenant=" +
            useAppStore().getCurrentTenant
        )
          .then((response) => {
            // make a name and value array list
            const data = response.data["results"];
            const urlList = [];
            data.forEach((element) => {
              urlList.push({
                id: element["id"],
                name: element["name"],
                value: element["url"],
              });
            });

            resolve(urlList);
          })
          .catch(() => {
            reject([]);
          });
      });
    },
    async saveIFrame(name, url) {
      return new Promise((resolve, reject) => {
        APIRequest("widgets/iframe/", "post", {
          name: name,
          url: url,
          tenant: useAppStore().getCurrentTenant,
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async updateIFrame(id, name, url) {
      return new Promise((resolve, reject) => {
        APIRequest(`widgets/iframe/${id}/`, "patch", {
          name: name,
          url: url,
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async deleteIFrame(id) {
      return new Promise((resolve, reject) => {
        APIRequest(`widgets/iframe/${id}/`, "delete")
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async openJson(url) {
      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async openTemplate(id) {
      return new Promise((resolve, reject) => {
        APIRequest(`/compose/presentation/${id}/`)
          .then((response) => {
            const result = response.data;
            if (
              result.source_type === "local" &&
              String(useAppStore().getCurrentTenant) !==
                String(result.source_ref_id)
            ) {
              reject();
            }

            if (
              result.source_type === "group" &&
              String(useAppStore().getTenantGroup) !==
                String(result.source_ref_id)
            ) {
              reject();
            }

            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async saveTemplate(title) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append(
          "template_data.json_file_path",
          new File([JSON.stringify(this.presentation)], `${Date.now()}.json`, {
            type: "application/json",
          })
        );

        formData.append(
          "template_data.thumbnail",
          this.presentation.slides[0].settings.thumbnail
        );
        formData.append(
          "template_data.orientation",
          this.presentationDetails.template.orientation
        );
        formData.append(
          "template_data.type",
          this.presentationDetails.template.type
        );
        formData.append("type", this.presentationDetails.type);
        formData.append("name", title);

        APIRequest(
          `compose/presentation/${this.presentationDetails.id}/`,
          "patch",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
          .then((response) => {
            // Resolve the Promise with the response data
            resolve(response.data);
          })
          .catch((error) => {
            // Reject the Promise with the error
            reject(error);
          });
      });
    },
    addSlide() {
      this.presentation.slides.push({
        id: Date.now(),
        settings: {
          show: true,
          lock: false,
          background: {
            type: "color",
            value: "#fff",
          },
          delay_type: false,
          transition: 10,
          animation: "fade",
          animation_time: 1,
          thumbnail: "",
        },
        elements: [],
      });
      this.saveDraft = true;
      localStorage.setItem("presentation", JSON.stringify(this.presentation));
    },
    addElement(element) {
      if (
        this.presentation.slides[this.indexDataArray].settings.lock === false
      ) {
        this.presentation.slides[this.indexDataArray].elements.push(element);
        this.saveDraft = true;
        this.indexElement = this.slideElements.length - 1;
        this.showMenuSettings = true;
        localStorage.setItem("presentation", JSON.stringify(this.presentation));
      }
    },
    deleteElement(index) {
      if (
        this.presentation.slides[this.indexDataArray].settings.lock === false
      ) {
        this.presentation.slides[this.indexDataArray].elements.splice(index, 1);
        this.saveDraft = true;
        this.settings = "default";
        this.indexElement = -1;
        this.showMenuSettings = false;
        this.configListView = false;
        this.check = true;
        localStorage.setItem("presentation", JSON.stringify(this.presentation));
      }
    },
    disableElements() {
      this.settings = "default";
      this.showMenuSettings = false;
      this.indexElement = -1;
      for (let i = 0; i < this.slideElements.length; i++) {
        this.slideElements[i].active = false;
      }
    },

    // MOVE WIDGETS
    moveWidget(event, multiple = false) {
        const max_x = this.presentation.orientation === "portrait" ? 1080 : 1920;
        const max_y = this.presentation.orientation === "portrait" ? 1920 : 1080;
        this.interacting = true;
    
        // Store initial mouse position
        const initialX = event.clientX;
        const initialY = event.clientY;
    
        // Helper function: throttle
        const throttle = (func, limit) => {
            let inThrottle;
            return function (...args) {
                if (!inThrottle) {
                    func.apply(this, args);
                    inThrottle = true;
                    setTimeout(() => (inThrottle = false), limit);
                }
            };
        };
    
        let mousemove = null;
    
        if (multiple) {
            // Selected elements logic
            const selectedElements = this.getSelectedItems;
    
            if (!selectedElements.length) return;
    
            // Calculate the group bounding box including rotation
            const allCorners = selectedElements.flatMap(el => {
                const elCenterX = el.left + el.width / 2;
                const elCenterY = el.top + el.height / 2;
                const angleRad = (el.angle || 0) * (Math.PI / 180);
    
                return [
                    { x: -el.width / 2, y: -el.height / 2 }, // top-left
                    { x: el.width / 2, y: -el.height / 2 },  // top-right
                    { x: el.width / 2, y: el.height / 2 },   // bottom-right
                    { x: -el.width / 2, y: el.height / 2 }   // bottom-left
                ].map(corner => ({
                    x: elCenterX + corner.x * Math.cos(angleRad) - corner.y * Math.sin(angleRad),
                    y: elCenterY + corner.x * Math.sin(angleRad) + corner.y * Math.cos(angleRad)
                }));
            });
    
            const minX = Math.min(...allCorners.map(corner => corner.x));
            const minY = Math.min(...allCorners.map(corner => corner.y));
            const maxX = Math.max(...allCorners.map(corner => corner.x));
            const maxY = Math.max(...allCorners.map(corner => corner.y));
    
            const groupWidth = maxX - minX;
            const groupHeight = maxY - minY;
    
            const initialGroupLeft = minX;
            const initialGroupTop = minY;
    
            const initialPositions = selectedElements.map(el => ({
                el,
                initialElementX: el.left,
                initialElementY: el.top
            }));

            mousemove = throttle((e) => {
                // Calculate delta
                const deltaX = (e.clientX - initialX) / this.zoom;
                const deltaY = (e.clientY - initialY) / this.zoom;
    
                // Calculate new group position with clamping
                const newGroupLeft = Math.min(
                    max_x - groupWidth,
                    Math.max(0, initialGroupLeft + deltaX)
                );
                const newGroupTop = Math.min(
                    max_y - groupHeight,
                    Math.max(0, initialGroupTop + deltaY)
                );
    
                // Calculate offsets for clamped group
                const clampedDeltaX = newGroupLeft - initialGroupLeft;
                const clampedDeltaY = newGroupTop - initialGroupTop;
                // Update individual elements relative to clamped group movement
                selectedElements.forEach((el, index) => {
                    const { initialElementX, initialElementY } = initialPositions[index];
                    el.left = initialElementX + clampedDeltaX;
                    el.top = initialElementY + clampedDeltaY;
                });
            }, 1); // Throttle interval in milliseconds
        } else {
            const slideElement = this.selectedWidget;
    
            // Store initial position for the single element
            const initialElementX = slideElement.left;
            const initialElementY = slideElement.top;
    
            mousemove = throttle((e) => {
                // Calculate delta
                const deltaX = (e.clientX - initialX) / this.zoom;
                const deltaY = (e.clientY - initialY) / this.zoom;
    
                // Calculate new position with clamping
                const newLeft = Math.min(
                    max_x - slideElement.width,
                    Math.max(0, initialElementX + deltaX)
                );
                const newTop = Math.min(
                    max_y - slideElement.height,
                    Math.max(0, initialElementY + deltaY)
                );
    
                // Update element position
                slideElement.left = newLeft;
                slideElement.top = newTop;
            }, 16); // Throttle interval in milliseconds
        }
    
        const mouseup = () => {
            this.addToHistory();
    
            this.interacting = false;
            document.removeEventListener("mousemove", mousemove);
            document.removeEventListener("mouseup", mouseup);
        };
    
        // Listen for mousemove and mouseup events
        document.addEventListener("mousemove", mousemove);
        document.addEventListener("mouseup", mouseup);
    },    

    // Resize element
    resizeWidget(event, position) {
      this.interacting = true;
  
      const canvasWidth = 1920;
      const canvasHeight = 1080;
  
      const selectedElements = this.getSelectedItems;
      if (!selectedElements.length) return;
  
      const groupBoundingBox = this.calculateGroupBoundingBox(selectedElements);
      const groupWidth = groupBoundingBox.right - groupBoundingBox.left;
      const groupHeight = groupBoundingBox.bottom - groupBoundingBox.top;
      const aspectRatio = groupWidth / groupHeight;
  
      const minGroupWidth = selectedElements.reduce(
          (max, el) => Math.max(max, el.min_width || 0),
          100
      );
  
      const initialX = event.clientX;
      const initialSizes = selectedElements.map(el => ({
          el,
          width: el.width,
          height: el.height,
          offsetX: el.left - groupBoundingBox.left,
          offsetY: el.top - groupBoundingBox.top,
      }));
  
      const mousemove = (e) => {
        const deltaX = (e.clientX - initialX) / this.zoom;
    
        let newLeft = groupBoundingBox.left;
        let newTop = groupBoundingBox.top;
        let newGroupWidth = groupWidth;
        let newGroupHeight = groupHeight;
    
        // Handle resizing logic for each corner
        if (position === 'tl') {
            newGroupWidth = Math.max(minGroupWidth, groupWidth - deltaX);
            newGroupHeight = newGroupWidth / aspectRatio;
            newLeft = groupBoundingBox.right - newGroupWidth;
            newTop = groupBoundingBox.bottom - newGroupHeight;
        } else if (position === 'tr') {
            newGroupWidth = Math.max(minGroupWidth, groupWidth + deltaX);
            newGroupHeight = newGroupWidth / aspectRatio;
            newTop = groupBoundingBox.bottom - newGroupHeight;
        } else if (position === 'bl') {
            newGroupWidth = Math.max(minGroupWidth, groupWidth - deltaX);
            newGroupHeight = newGroupWidth / aspectRatio;
            newLeft = groupBoundingBox.right - newGroupWidth;
        } else if (position === 'br') {
            newGroupWidth = Math.max(minGroupWidth, groupWidth + deltaX);
            newGroupHeight = newGroupWidth / aspectRatio;
        }
    
        // Enforce canvas boundaries for the group
        if (newLeft < 0) {
            // Clamp newLeft to 0 and adjust newGroupWidth
            newLeft = 0;
    
            // Recalculate newGroupWidth based on fixed right boundary
            newGroupWidth = groupBoundingBox.right;
    
            // Maintain aspect ratio if applicable
            newGroupHeight = newGroupWidth / aspectRatio;
    
            // Only adjust newTop for top handles ('tl' or 'tr')
            if (position === 'tl' || position === 'tr') {
                newTop = groupBoundingBox.bottom - newGroupHeight;
            }
        }
    
        if (newTop < 0) {
            // Clamp newTop to 0 and adjust newGroupHeight
            newTop = 0;
    
            // Recalculate newGroupHeight based on fixed bottom boundary
            newGroupHeight = groupBoundingBox.bottom;
    
            // Maintain aspect ratio if applicable
            newGroupWidth = newGroupHeight * aspectRatio;
    
            // Only adjust newLeft for left handles ('tl' or 'bl')
            if (position === 'tl' || position === 'bl') {
                newLeft = groupBoundingBox.right - newGroupWidth;
            }
        }
    
        if (newLeft + newGroupWidth > canvasWidth) {
            // Clamp newGroupWidth to the right boundary
            newGroupWidth = canvasWidth - newLeft;
    
            // Maintain aspect ratio if applicable
            newGroupHeight = newGroupWidth / aspectRatio;
    
            // Only adjust newTop for top handles ('tl' or 'tr')
            if (position === 'tl' || position === 'tr') {
                newTop = groupBoundingBox.bottom - newGroupHeight;
            }
        }
    
        if (newTop + newGroupHeight > canvasHeight) {
            // Clamp newGroupHeight to the bottom boundary
            newGroupHeight = canvasHeight - newTop;
    
            // Maintain aspect ratio if applicable
            newGroupWidth = newGroupHeight * aspectRatio;
    
            // Only adjust newLeft for left handles ('tl' or 'bl')
            if (position === 'tl' || position === 'bl') {
                newLeft = groupBoundingBox.right - newGroupWidth;
            }
        }
    
          // Ensure no element goes below its minimum size
          let minWidthViolated = false;
          let minHeightViolated = false;
      
          const widthRatio = newGroupWidth / groupWidth;
          const heightRatio = newGroupHeight / groupHeight;
      
          selectedElements.forEach((_, i) => {
              const { el, width, height } = initialSizes[i];
      
              const newWidth = Math.max(el.min_width || 0, width * widthRatio);
              const newHeight = Math.max(el.min_height || 0, height * heightRatio);
      
              if (newWidth <= el.min_width) minWidthViolated = true;
              if (newHeight <= el.min_height) minHeightViolated = true;
          });
      
          if (minWidthViolated || minHeightViolated) {
              return; // Halt resizing if constraints are violated
          }
      
          // Apply resizing to elements
          selectedElements.forEach((_, i) => {
              const { el, width, height, offsetX, offsetY } = initialSizes[i];
      
              el.width = Math.max(el.min_width || 0, width * widthRatio);
              el.height = Math.max(el.min_height || 0, height * heightRatio);
      
              if (position.includes('l') || position.includes('r')) {
                  el.left = newLeft + offsetX * widthRatio;
              }
              if (position.includes('t') || position.includes('b')) {
                  el.top = newTop + offsetY * heightRatio;
              }
          });
      };
      
    
        const mouseup = () => {
            this.addToHistory();
    
            this.interacting = false;
            document.removeEventListener('mousemove', mousemove);
            document.removeEventListener('mouseup', mouseup);
        };
    
        document.addEventListener('mousemove', mousemove);
        document.addEventListener('mouseup', mouseup);
    },      
    calculateGroupBoundingBox(elements) {
      return elements.reduce(
          (box, el) => ({
              left: Math.min(box.left, el.left),
              top: Math.min(box.top, el.top),
              right: Math.max(box.right, el.left + el.width),
              bottom: Math.max(box.bottom, el.top + el.height),
          }),
          { left: Infinity, top: Infinity, right: -Infinity, bottom: -Infinity }
      );
  },
    unSelectElements(){
      this.indexElement = -1;
      this.configListView = false;
      this.handleUnselect();
    },
    handleUnselect(){
      this.selectedItemNum = 0;
      this.getSelectedItems.forEach((element) => {
        element.isSelected = false;
      });
    }
  },
  getters: {
    getSelectedItems(){
      return this.slideElements.filter((element) => element.isSelected);
    },
    getSelectedItemsGroup(){
      // Check if all elements have the same group_id and it's not undefined
      const groupIds = this.getSelectedItems.map(element => element.group_id);

      // If any element does not have a group_id, return false
      if (groupIds.includes(undefined)) {
        return false;
      }
  
      // Check if all group_ids are the same
      const isSameGroup = groupIds.every(groupId => groupId === groupIds[0]);
    
      return isSameGroup;
    },
    getSelectedItemsGroupId(){
      const groupIds = this.getSelectedItems.map(element => element.group_id);
      if (groupIds.includes(undefined)) return null;
      return groupIds[0];
    },
    canUndo() {
      return this.historyIndex > 0;
    },
    canRedo() {
      return this.historyIndex < this.history.length - 1;
    },
    isSlideLocked() {
      return (
        this.presentation.slides[this.indexDataArray]?.settings?.lock ||
        this.presentation.slides[this.indexDataArray]?.settings?.admin_locked
      );
    },
    isWidgetLocked() {
      return (
        this.selectedWidget?.settings?.lock ||
        this.selectedWidget?.settings?.admin_locked
      );
    },
    slideElements() {
      return this.presentation.slides[this.indexDataArray]?.elements || [];
    },
    selectedWidget() {
      if (!this.presentation) return null;
      return this.slideElements[this.indexElement];
    },
    checkNBrowserWidgets() {
      let found = false;
      this.slideElements.forEach((element) => {
        if (["VideoWidget", "TVWidget"].includes(element.name) || this.videoPlaylistExists) found = true;
      });

      if (found) return true;
      return false;
    },
    hasVideoWidget() {
      if (!this.slideElements) return false;
      if (this.slideElements.find((element) => element.name === "VideoWidget"))
        return true;
      return false;
    },
    currentWidgetNBrowser() {
      if(this.selectedItemNum > 1) {
        let found = false;

        this.getSelectedItems.forEach((element) => {
          if (["VideoWidget", "TVWidget", "MediaPlaylistWidget"].includes(element.name)) found = true;
        });
        if(found) return true;
        return false;
      }
      
      if (!this.slideElements[this.indexElement]) return false;
      if (
        ["VideoWidget", "TVWidget", "MediaPlaylistWidget"].includes(
          this.slideElements[this.indexElement].name
        )
      )
        return true;
      return false;
    },
    hasLockPermissions() {
      return ["client-admin", "nonius-admin"].includes(
        useAppStore().getCurrentRole
      );
    },
    rightMenuOpen() {
      return this.showGallery || this.settings == "TVWidget";
    },
    videoPlaylistExists() {
      return this.slideElements.some(
        (element) => element.name === "MediaPlaylistWidget" && element?.settings?.playlist && element?.settings?.playlist_type === 'Video'
      );
    },
    isSelectedVideoPlaylist() {
      if(this.selectedWidget?.name === "MediaPlaylistWidget" && this.selectedWidget?.settings?.playlist && this.selectedWidget?.settings?.playlist_type === 'Video') {
        return true;
      }
      return false;
    }
  },
});
